import React from 'react'
import * as useragent from 'express-useragent'
import cf from '@/lib/utils/common'
import { getHomePageData } from '../../common/lib/Homepage'
import { getSiteData } from '../../common/lib/auth/authhelpers'
import Theme1Flow from '@/components/ThemeFlowComp/Theme1Flow/Desktop'
import Theme2Flow from '@/components/ThemeFlowComp/Theme2Flow/Desktop'
import Theme4Flow from '@/components/ThemeFlowComp/Theme4Flow/Desktop'
import Theme5Flow from '@/components/ThemeFlowComp/Theme5Flow/Desktop'
import Theme7Flow from '@/components/ThemeFlowComp/Theme7Flow/Desktop'
import Theme8Flow from '@/components/ThemeFlowComp/Theme8Flow/Desktop'
import MobileTheme1 from '@/components/ThemeFlowComp/Theme1Flow/Mobile'
import MobileTheme2 from '@/components/ThemeFlowComp/Theme2Flow/Mobile'

export default function Home(props) {
  
  const theme = props?.home?.data?.site?.site_theme
  
  switch (theme) {
    case 'theme1':
      if (props.isMobile) {
        return <MobileTheme1 {...props} />
      } else {
        return <Theme1Flow {...props} />
      }
      break
    case 'theme2':
      if (props.isMobile) {
        return <MobileTheme2 {...props} />
      } else {
        return <Theme2Flow {...props} />
      }
      break
    case 'theme4':
      return <Theme4Flow {...props} />
      break
    case 'theme5':
      return <Theme5Flow {...props} />
      break
    case 'theme7':
      return <Theme7Flow {...props} />
      break
    case 'theme8':
      return <Theme8Flow {...props} />
      break
    default:
      if (props.isMobile) {
        return <MobileTheme1 {...props} />
      } else {
        return <Theme1Flow {...props} />
      }
  }
}

export async function getServerSideProps({ req }) {
  try {
    let data = {}
    //await getSiteData(req)
    //let site_id = req?.headers?.site_id

    let homeData = await getHomePageData(req) // home pagedata

    //console.log('🚀 ~ getServerSideProps ~ homeData:', homeData)

    // for b2b login by aarti
    if (homeData?.site?.business_b2b && homeData?.data?.access_token == '') {
      return {
        redirect: {
          permanent: false,
          destination: '/login',
        },
      }
    }

    if (!cf.isValueEmpty(homeData) && homeData.status === true && !cf.isValueEmpty(homeData.data)) {
      data = { status: true, data: homeData }
    }
    //console.log('=====home page data :', data)
    //console.log('=====home page data site_id:', data.data.site.id)
    return {
      props: {
        isMobile: useragent.parse(req.headers['user-agent']).isMobile,
        home: data,
        site_id: data.data.site.id,
        //site_id: site_id,
      },
    }
  } catch (error) {
    console.log('error', error)
    return {
      props: {
        isMobile: useragent.parse(req.headers['user-agent']).isMobile,
      },
    }
  }
}
